import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';

// PAGES ======================================================================
import HomePage from "./pages/HomePage";
import AboutUsPage from "./pages/AboutUsPage";
import ServicesPage from "./pages/ServicesPage";
import Industries from "./pages/IndustriesPage";
import OurproductsPage from "./pages/OurProductsPage";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUsPage />} forAboutpage={true} />
        <Route path="/our-services" element={<ServicesPage />} />
        <Route path="/industries" element={<Industries />} />
        <Route path="/our-products" element={<OurproductsPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
