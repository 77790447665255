import Js from './toolkitIcons/js.svg';
import Ts from './toolkitIcons/ts.svg';
import NodeJs from './toolkitIcons/node.svg';
import Php from './toolkitIcons/php.svg';
import Aws from './toolkitIcons/aws.svg';
import Mongo from './toolkitIcons/mongo.svg';
import Sql from './toolkitIcons/sql.svg';
import MySql from './toolkitIcons/mysql.svg';
import Api from './toolkitIcons/api.svg';
import ReactJs from './toolkitIcons/react.svg';
import Angular from './toolkitIcons/angular.svg';
import EspressJs from './toolkitIcons/express.svg';
import VueJs from './toolkitIcons/vue.svg';
import Laravel from './toolkitIcons/laravel.svg';
import ReactNative from './toolkitIcons/react.svg';
import Figma from './toolkitIcons/figma.svg';
import Midjourney from './toolkitIcons/midjourney.svg';
import Xd from './toolkitIcons/xd.svg';
import Illustrator from './toolkitIcons/illustrator.svg';
import Photoshop from './toolkitIcons/photoshop.svg';
import Lightroom from './toolkitIcons/lightroom.svg';
import Magento from './toolkitIcons/magento.svg';
import Drupal from './toolkitIcons/drupal.svg';
import Wordpress from './toolkitIcons/wordpresss.svg';



const Tools = [
  { id: 1, src: Js, title: 'JavaScript', alt: 'JavaScript' },
  { id: 2, src: Ts, title: 'TypeScript', alt: 'TypeScript' },
  { id: 4, src: NodeJs, title: 'NodeJS', alt: 'NodeJS' },
  { id: 5, src: Php, title: 'PHP', alt: 'PHP' },
  { id: 6, src: Aws, title: 'AWS', alt: 'AWS' },
  { id: 7, src: Mongo, title: 'MongoDB', alt: 'MongoDB' },
  { id: 8, src: Sql, title: 'SQL', alt: 'SQL' },
  { id: 9, src: MySql, title: 'MySQL', alt: 'MySQL' },
  { id: 10, src: Api, title: 'API', alt: 'API' },
  { id: 11, src: ReactJs, title: 'ReactJS', alt: 'ReactJS' },
  { id: 12, src: Angular, title: 'Angular', alt: 'Angular' },
  { id: 13, src: EspressJs, title: 'ExpressJS', alt: 'ExpressJS' },
  { id: 14, src: VueJs, title: 'VueJs', alt: 'VueJs' },
  { id: 15, src: Laravel, title: 'Laravel', alt: 'Laravel' },
  { id: 16, src: ReactNative, title: 'React Native', alt: 'React Native' },
  { id: 17, src: Figma, title: 'Figma', alt: 'Figma' },
  { id: 18, src: Midjourney, title: 'Midjourney', alt: 'Midjourney' },
  { id: 19, src: Xd, title: 'XD', alt: 'XD' },
  { id: 20, src: Illustrator, title: 'Illustrator', alt: 'Illustrator' },
  { id: 20, src: Photoshop, title: 'Photoshop', alt: 'Photoshop' },
  { id: 20, src: Lightroom, title: 'Lightroom', alt: 'Lightroom' },
  { id: 20, src: Magento, title: 'Magento', alt: 'Magento' },
  { id: 20, src: Drupal, title: 'Drupal', alt: 'Drupal' },
  { id: 20, src: Wordpress, title: 'Wordpress', alt: 'Wordpress' }
]

export default Tools