import { useNavigate } from 'react-router-dom';
import styles from './style.module.css';

const SkewButton = ({
  ButtonTitle,
  forLeftWhite,
  forRightWhite,
  forLeftBG,
  forRightBG
}) => {

  let btn;
  let shadow;
  if (forLeftWhite) {
    btn = styles.btn_left_white
    shadow = styles.shadow_left_white
  } else if (forRightWhite) {
    btn = styles.btn_right_white
    shadow = styles.shadow_right_white
  } else if (forRightBG) {
    btn = styles.btn_right_color
    shadow = styles.shadow_right_color
  } else if (forLeftBG) {
    btn = styles.btn_left_color
    shadow = styles.shadow_left_color
  }

  const navigate = useNavigate()

  const hadleClickScrolltoForm = () => {
    // window.scrollTo({
    //   top: document.documentElement.scrollHeight,
    //   behavior: 'smooth'
    // })
    document.getElementById('Form-Content').scrollIntoView({ behavior: 'smooth' })
  }

  const handleClick = () => {
    if (forRightBG) {
      navigate('/about-us');
    } else if (forLeftBG) {
      navigate('/our-services');
    } else {
      hadleClickScrolltoForm()
    }
  }

  return (
    <div style={{ position: 'relative', height: '50px' }} >
      <button type='button' className={btn} onClick={handleClick} >
        <span>{ButtonTitle}</span>
      </button>
      <div className={shadow}></div>
    </div>
  )


}

export default SkewButton
