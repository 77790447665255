import Head from "../components/productsHead/Head"


const OurProductsPage = () => {
  return (
    <>
     <Head />
    </>
  )
}

export default OurProductsPage
