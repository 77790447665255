import styles from './style.module.css';
import Nav from '../navigation/Nav';


const Head = () => {
  return (
    <section className={styles.section}>
      <Nav />
      <div className='container'>
        <div className={styles.content}>
          <h1 className={styles.title}>
            Worx IT Services for Advanced<br /> Technology Solutions
          </h1>
          <p className={styles.descr}>
            Our team offers a variety of services, ranging from innovative software development
            to comprehensive IT support, all designed to enhance your digital journey and drive your success.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Head
