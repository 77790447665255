import Toolkit from "../components/ourToolkit/Toolkit"
import Head from "../components/servicesHead/Head"
import ServicesItems from "../components/servicesItems/ServicesItems"
import WorkingProcess from "../components/workingProcess/WorkingProcess"

const ServicesPage = () => {
  return (
    <>
      <Head />
      <ServicesItems />
      <Toolkit />
      <WorkingProcess />
    </>
  )
}

export default ServicesPage
