import Company from "../components/company/Company";
import Head from "../components/industriesHead/Head";
import Experience from "../components/ourExperince/Experience";
import OurIndustries from "../components/ourIndustries/OurIndustries";


const Industries = () => {
  return (
    <>
      <Head />
      <OurIndustries />
      <Experience />
      <Company />
    </>
  )
}

export default Industries
