import styles from './style.module.css';

const Worx = () => {
  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.content}>
          <div className={styles.title_block}>
            <h4 className={styles.suptitle}>Worx</h4>
            <h2 className={styles.title}>Who we are</h2>
          </div>
          <p className={styles.descr}>Worx is an IT organization with over five years of market experience.
            We bring our clients' most ambitious ideas to life and contribute to IT development.
            Throughout our practice, we have developed everything from simple websites to complex
            business solutions. We have also designed and implemented numerous business automation
            programs to streamline employee workflows and document circulation.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Worx
