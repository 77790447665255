import styles from './style.module.css';
import React, { useEffect, useRef } from 'react';

const Clock = () => {

  const secondHandRef = useRef(null);
  const minsHandRef = useRef(null);

  useEffect(() => {
    function setDate() {
      const now = new Date();

      const seconds = now.getSeconds();
      const secondsDegrees = ((seconds / 60) * 360) + 90;
      secondHandRef.current.style.transform = `rotate(${secondsDegrees}deg)`;

      const mins = now.getMinutes();
      const minsDegrees = ((mins / 60) * 360) + ((seconds / 60) * 6) + 90;
      minsHandRef.current.style.transform = `rotate(${minsDegrees}deg)`;
    }
    setDate();
    const intervalId = setInterval(setDate, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={styles.clock_wrapper}>
      <div className={styles.outer_clock_face}>
        <div className={styles.inner}>
          <span className={styles.clock_suptitle}>OUR NEW PRODUCT IS</span>
          <span className={styles.clock_title}>COMING<br /> SOON</span>
          <span className={styles.clock_subtitle}>STAY TUNED!</span>
        </div>
        <div className={`${styles.one} ${styles.line}`}></div>
        <div className={`${styles.two} ${styles.line}`}></div>
        <div className={`${styles.three} ${styles.line}`}></div>
        <div className={`${styles.four} ${styles.line}`}></div>
        <div className={`${styles.five} ${styles.line}`}></div>
        <div className={`${styles.six} ${styles.line}`}></div>
        <div className={`${styles.seven} ${styles.line}`}></div>
        <div className={`${styles.eight} ${styles.line}`}></div>
        <div className={`${styles.nine} ${styles.line}`}></div>
        <div className={`${styles.ten} ${styles.line}`}></div>
        <div className={`${styles.eleven} ${styles.line}`}></div>
        <div className={`${styles.twelve} ${styles.line}`}></div>

        {/* <div className={`${styles.marking} ${styles.marking_one}`}></div>
            <div className={`${styles.marking} ${styles.marking_two}`}></div>
            <div className={`${styles.marking} ${styles.marking_three}`}></div>
            <div className={`${styles.marking} ${styles.marking_four}`}></div>
            <div className={`${styles.marking} ${styles.marking_five}`}></div>
            <div className={`${styles.marking} ${styles.marking_six}`}></div>
            <div className={`${styles.marking} ${styles.marking_seven}`}></div>
            <div className={`${styles.marking} ${styles.marking_eight}`}></div>
            <div className={`${styles.marking} ${styles.marking_nine}`}></div>
            <div className={`${styles.marking} ${styles.marking_ten}`}></div>
            <div className={`${styles.marking} ${styles.marking_eleven}`}></div>
            <div className={`${styles.marking} ${styles.marking_twelve}`}></div> */}

        <div className={styles.inner_clock_face}></div>
        <div className={`${styles.hand} ${styles.min_hand}`} ref={minsHandRef}></div>
        <div className={`${styles.hand} ${styles.second_hand}`} ref={secondHandRef}></div>
      </div>
    </div>
  )
}

export default Clock;
