import styles from './style.module.css';

import Persons from '../../images/for-values.png';

const Values = () => {
  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.content}>
          <div className={styles.title_block}>
            <h4 className={styles.suptitle}>Our Values</h4>
            <h2 className={styles.title}>What we believe in</h2>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.left_side}>
              <div className={styles.item}>
                <div className={styles.item_number}>01</div>
                <div className={styles.item_title__block}>
                  <h3 className={styles.item_title}>Partnership</h3>
                  <p className={styles.item_subtitle}>Putting the needs of our clients first.</p>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.item_number}>02</div>
                <div className={styles.item_title__block}>
                  <h3 className={styles.item_title}>Transparency</h3>
                  <p className={styles.item_subtitle}>Ensuring that stakeholders have a window into every process.</p>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.item_number}>03</div>
                <div className={styles.item_title__block}>
                  <h3 className={styles.item_title}>Flexibility</h3>
                  <p className={styles.item_subtitle}>Giving the ability to change direction as and when needed. </p>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.item_number}>04</div>
                <div className={styles.item_title__block}>
                  <h3 className={styles.item_title}>Sincerity</h3>
                  <p className={styles.item_subtitle}>cutting out the corporate jargon and being as direct as possible. </p>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.item_number}>05</div>
                <div className={styles.item_title__block}>
                  <h3 className={styles.item_title}>Support</h3>
                  <p className={styles.item_subtitle}>taking care of our talent and developing their skills for the future. </p>
                </div>
              </div>
            </div>
            <div className={styles.right_side}>
              <img src={Persons} alt="Persons" />
              <span className={styles.img_descr}>We Innovate, We Inspire, We Transform – Together in IT.</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Values
