// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/industries-head-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_section__86JMO {
  width: 100%;
  height: 572px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: center center;
  margin-bottom: 75px;
}

.style_title__NyTpK {
  color: #FFF;
  text-align: right;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 170px;
}

/* ========================== Media Queries =================================  */
@media screen and (max-width: 768px) {
  .style_content__UPZch {
    display: flex;
    justify-content: center;
  }

  .style_title__NyTpK {
    text-align: center;
  }
}

@media screen and (max-width: 520px) and (min-width: 368px) {
  .style_title__NyTpK {
    font-size: 34px;
  }
}
@media screen and (max-width: 367px) {
  .style_title__NyTpK {
    font-size: 28px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/industriesHead/style.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,yDAA0D;EAC1D,sBAAsB;EACtB,kCAAkC;EAClC,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA,gFAAgF;AAChF;EACE;IACE,aAAa;IACb,uBAAuB;EACzB;;EAEA;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF;AACA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".section {\n  width: 100%;\n  height: 572px;\n  background-image: url(../../images/industries-head-bg.png);\n  background-size: cover;\n  background-position: center center;\n  margin-bottom: 75px;\n}\n\n.title {\n  color: #FFF;\n  text-align: right;\n  font-size: 50px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  padding-top: 170px;\n}\n\n/* ========================== Media Queries =================================  */\n@media screen and (max-width: 768px) {\n  .content {\n    display: flex;\n    justify-content: center;\n  }\n\n  .title {\n    text-align: center;\n  }\n}\n\n@media screen and (max-width: 520px) and (min-width: 368px) {\n  .title {\n    font-size: 34px;\n  }\n}\n@media screen and (max-width: 367px) {\n  .title {\n    font-size: 28px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `style_section__86JMO`,
	"title": `style_title__NyTpK`,
	"content": `style_content__UPZch`
};
export default ___CSS_LOADER_EXPORT___;
