import styles from './style.module.css';
// import { Link } from 'react-router-dom';

import Logo from '../../icons/footer-worx-logo.svg';
import Facebook from '../../icons/facebook.svg';
import Linkedin from '../../icons/linkedin.svg';


const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <span className={styles.copyright}>Copyright &#169; 2024 All rights reserved.</span>
        <div className={styles.logo_block} >
          <a href='/'>
            <img src={Logo} alt="logo" className={styles.logo} style={{ cursor: 'pointer' }} />
          </a>
          
        </div>

        <div className={styles.social_block}>
          <div className={styles.social}>
            <a className={styles.social_link} href="https://www.facebook.com/worxarmenia" target="_blank">
              <img src={Facebook} alt="" />
            </a>
          </div>
          <div className={styles.social}>
            <a className={styles.social_link} href="https://www.linkedin.com/company/worx-llc/?viewAsMember=true" target="_blank">
              <img src={Linkedin} alt="" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
