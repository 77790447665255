import styles from './style.module.css';
import SkewButton from '../skewButton/SkewButton';
import services from '../../services/services';

const OurServices = () => {

  const recordedServices = [
    services[0],  // Example: This will render the third item first
    services[1],
    services[3],
    services[2],
    services[5]
  ]

  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.content}>
          <div className={styles.service_item}>
            <h3 className={styles.service_item__title}>Our services</h3>
            <h2 className={styles.service_item__subtitle}>Solutions & Focus Areas</h2>
            <p className={styles.service_item__descr}>Empowering your business with innovative technology solutions.</p>
            <SkewButton forLeftBG={true} ButtonTitle='All Services' />
          </div>
          {recordedServices.map((item, index) => (
            <div className='item' key={item.id}>
              <img className={item.class} src={item.src} alt={item.title} />
              <h2 className='item_title'>{item.title}</h2>
              <p className='item_descr'>
                {item.descr}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default OurServices
