// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_section__dWCiN {
  margin: 68px 0 150px 0;
}

.style_content__JaZrt {
  display: flex;
  gap: 80px;
}

.style_title_block__\\+nvCN {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  width: 910px;
}

.style_suptitle__mQLno {
  color: #699595;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.style_title__fDD53 {
  color: #455A64;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.style_descr__jEkTK {
  color: #434343;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
/*  ==================================== Media Queries ========================================== */
@media screen and (max-width: 1024px) {
  .style_title_block__\\+nvCN {
      text-align: center;
    }
  
    .style_title__fDD53 {
      font-size: 23px;
    }
  
    .style_suptitle__mQLno {
      font-size: 12px;
    }
}
@media screen and (max-width: 1045px) {
  .style_content__JaZrt {
    flex-wrap: wrap;
    text-align: center;
    gap: 25px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/worx/style.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;AACA,mGAAmG;AACnG;EACE;MACI,kBAAkB;IACpB;;IAEA;MACE,eAAe;IACjB;;IAEA;MACE,eAAe;IACjB;AACJ;AACA;EACE;IACE,eAAe;IACf,kBAAkB;IAClB,SAAS;EACX;AACF","sourcesContent":[".section {\n  margin: 68px 0 150px 0;\n}\n\n.content {\n  display: flex;\n  gap: 80px;\n}\n\n.title_block {\n  display: flex;\n  flex-direction: column;\n  row-gap: 6px;\n  width: 910px;\n}\n\n.suptitle {\n  color: #699595;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n}\n\n.title {\n  color: #455A64;\n  font-size: 25px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n}\n\n.descr {\n  color: #434343;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 22px;\n}\n/*  ==================================== Media Queries ========================================== */\n@media screen and (max-width: 1024px) {\n  .title_block {\n      text-align: center;\n    }\n  \n    .title {\n      font-size: 23px;\n    }\n  \n    .suptitle {\n      font-size: 12px;\n    }\n}\n@media screen and (max-width: 1045px) {\n  .content {\n    flex-wrap: wrap;\n    text-align: center;\n    gap: 25px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `style_section__dWCiN`,
	"content": `style_content__JaZrt`,
	"title_block": `style_title_block__+nvCN`,
	"suptitle": `style_suptitle__mQLno`,
	"title": `style_title__fDD53`,
	"descr": `style_descr__jEkTK`
};
export default ___CSS_LOADER_EXPORT___;
