import styles from './style.module.css';
import {  useRef } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import Arrowleft from '../../icons/arrow-left.svg';
import ArrowRight from '../../icons/arrow-right.svg';
import Person1 from '../../images/person1.png';
import Person2 from '../../images/person2.png';
import Person3 from '../../images/person3.png';



const GetTOknow = () => {

  const carouselRef = useRef();

  const handleButtonClick = (direction) => {
    if (direction === 'prev') {
      carouselRef.current.slidePrev();
    } else if (direction === 'next') {
      carouselRef.current.slideNext();
    }
  };

  const items = [
    <div className={styles.right_side}>
      <div className={styles.right_side__item}>
        <p className={styles.right_side_item__title}>
          Starting with Worx on our Caspio project was a breeze. They were patient,
          knew their stuff, and always ready to help. Highly recommend them for Caspio work.
        </p>
        <h4 className={styles.name}>David Rodriguez</h4>
        <p className={styles.profession}>Product Owner</p>
        <img className={styles.person} src={Person1} alt="" />
      </div>
    </div>,
    <div className={styles.right_side}>
      <div className={styles.right_side__item}>
        <p className={styles.right_side_item__title}>
          Working with Worx has been great. They're skilled, pay attention to detail, and collaborate well.
          If you need solid software development, look no further.
        </p>
        <h4 className={styles.name}>Lauren Miller</h4>
        <p className={styles.profession}>CEO of Digital Dynamics</p>
        <img className={styles.person} src={Person2} alt="" />
      </div>
    </div>,
    <div className={styles.right_side}>
      <div className={styles.right_side__item}>
        <p className={styles.right_side_item__title}>
          Our experience with Worx made a positive difference. Their expertise in software development streamlined our
          operations effectively. Looking forward to more projects together.
        </p>
        <h4 className={styles.name}>Alex Patel</h4>
        <p className={styles.profession}>IT Manager</p>
        <img className={styles.person} src={Person3} alt="" />
      </div>
    </div>,
 /*  <div className={styles.right_side}>
      <div className={styles.right_side__item}>
        <p className={styles.right_side_item__title}>
          Sed ut perspiciatis, unde omnis iste natus error sit
          voluptatem unde omnis iste natus error sit voluptatem
        </p>
        <h4 className={styles.name}>Susan Hok</h4>
        <p className={styles.profession}>Buisness Lady</p>
        <img className={styles.person} src={Person2} alt="" />
      </div>
    </div>,
    <div className={styles.right_side}>
      <div className={styles.right_side__item}>
        <p className={styles.right_side_item__title}>
          Sed ut perspiciatis, unde omnis iste natus error sit
          voluptatem unde omnis iste natus error sit voluptatem
        </p>
        <h4 className={styles.name}>John Smith</h4>
        <p className={styles.profession}>Developer</p>
        <img className={styles.person} src={Person1} alt="" />
      </div>
    </div> */
  ];

  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.content}>
          <div className={styles.left_side}>
            <h3 className={styles.left_side__title}>Get To Know Us</h3>
            <h2 className={styles.left_side__subtitle}>What Clients Talk About us</h2>
            <p className={styles.left_side__descr}>Trusted by more than 500 clients</p>
            <div className={styles.arrows_block} >
              <div className={styles.left_arrow} onClick={() => handleButtonClick('prev')}>
                <img src={Arrowleft} alt="" />
              </div>
              <div className={styles.right_arrow} onClick={() => handleButtonClick('next')}>
                <img src={ArrowRight} alt="" />
              </div>
            </div>
          </div>
          <AliceCarousel
            activeIndex={0}
            infinite={true}
            disableDotsControls={true}
            items={items}
            autoWidth={true}
            ref={carouselRef}
          />
        </div>
      </div>
    </section>
  )
}

export default GetTOknow
