import styles from './style.module.css';
import Nav from '../navigation/Nav';

const Head = () => {
  return (
    <section className={styles.section}>
      <Nav />
      <div className="container">
        <div className="content">
          <h1 className={styles.title}>Our Industries and<br /> Experience</h1>
        </div>
      </div>
    </section>
  )
}

export default Head
