import styles from './style.module.css';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';

import Logo from '../../logo.svg';
import Facebook from '../../icons/facebook.svg';
import Linkedin from '../../icons/linkedin.svg';




const Nav = () => {
  const [clicked, setClicked] = useState("");
  const [isStyleChanged, setIsStyleChanged] = useState(false);
  const handleClick = () => {
    setClicked(!clicked)
    setIsStyleChanged(!isStyleChanged)
  }

  return (
    <section className={styles.section}>
      <nav className={styles.nav}>
        <div className={styles.logo}>
          <a href="/" >
            <img src={Logo} alt="logo" height={'50px'} width={'50px'} />
          </a>
        </div>
        <ul className={styles.nav_list}>
          <li className={styles.list_item}>
            {/* <a className={styles.nav_link} href="/about-us">About us</a> */}
            <NavLink className={styles.nav_link} style={({ isActive }) => {
              return isActive ? { borderBottom: "3px solid #699595", pointerEvents: 'none' } : {};
            }} to="/about-us">About us</NavLink>
          </li>
          <li className={styles.list_item}>
            {/* <a className={styles.nav_link} href="/our-services">Services</a> */}
            <NavLink className={styles.nav_link} style={({ isActive }) => {
              return isActive ? { borderBottom: "3px solid #699595", pointerEvents: 'none' } : {};
            }} to="/our-services">Services</NavLink>
          </li>
          <li className={styles.list_item}>
            {/* <a className={styles.nav_link} href="/industries">Industries</a> */}
            <NavLink className={styles.nav_link} style={({ isActive }) => {
              return isActive ? { borderBottom: "3px solid #699595", pointerEvents: 'none' } : {};
            }} to="/industries">Industries</NavLink>
          </li>
          <li className={styles.list_item}>
            {/* <a className={styles.nav_link} href="/our-products">Our Products</a> */}
            <NavLink className={styles.nav_link} style={({ isActive }) => {
              return isActive ? { borderBottom: "3px solid #699595", pointerEvents: 'none' } : {};
            }} to="/our-products">Our Products</NavLink>
          </li>
        </ul>
        <div className={styles.burger_menu} onClick={handleClick}>
          <span className={`${styles.display_el} ${clicked === "" ? "" : clicked ? styles.open_menu : styles.close_menu}`} />
          <span className={`${styles.display_el} ${clicked === "" ? "" : clicked ? styles.open_menu : styles.close_menu}`} />
          <span className={`${styles.display_el} ${clicked === "" ? "" : clicked ? styles.open_menu : styles.close_menu}`} />
        </div>
      </nav>
      {/* =========================Burger Menu Wrapper =========================== */}
      <div className={`${isStyleChanged ? styles.moove_el : styles.burger_menu__wrapper}`} >
        <ul className={styles.burger_menu__list}>
          <li className={styles.list_item}>
            {/* <a className={styles.nav_link} href="/about-us">About us</a> */}
            <NavLink className={styles.nav_link} style={({ isActive }) => {
              return isActive ? { borderBottom: "3px solid #699595", pointerEvents: 'none' } : {};
            }} to="/about-us">About us</NavLink>
          </li>
          <li className={styles.list_item}>
            {/* <a className={styles.nav_link} href="/our-services">Services</a> */}
            <NavLink className={styles.nav_link} style={({ isActive }) => {
              return isActive ? { borderBottom: "3px solid #699595", pointerEvents: 'none' } : {};
            }} to="/our-services">Services</NavLink>
          </li>
          <li className={styles.list_item}>
            {/* <a className={styles.nav_link} href="/industries">Industries</a> */}
            <NavLink className={styles.nav_link} style={({ isActive }) => {
              return isActive ? { borderBottom: "3px solid #699595", pointerEvents: 'none' } : {};
            }} to="/industries">Industries</NavLink>
          </li>
          <li className={styles.list_item}>
            {/* <a className={styles.nav_link} href="/our-products">Our Products</a> */}
            <NavLink className={styles.nav_link} style={({ isActive }) => {
              return isActive ? { borderBottom: "3px solid #699595", pointerEvents: 'none' } : {};
            }} to="/our-products">Our Products</NavLink>
          </li>
        </ul>
        <div className={styles.social_block}>
          <div className={styles.social}>
            <a className={styles.social_link} href="https://www.facebook.com/worxarmenia" target="_blank">
              <img src={Facebook} alt="" />
            </a>
          </div>
          <div className={styles.social}>
            <a className={styles.social_link} href="https://www.linkedin.com/company/worx-llc/?viewAsMember=true" target="_blank">
              <img src={Linkedin} alt="" />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Nav
