import styles from './style.module.css';

const Experience = () => {
  return (
    <section className={styles.section}>
      <div className={styles.polygon1}></div>
      <div className="container">
        <div className={styles.content}>
          <div className={styles.title_block}>
            <h4 className={styles.suptitle}>Operational successes</h4>
            <h2 className={styles.title}>Experience</h2>
          </div>
          
          <div className={styles.wrapper}>
            <div className={styles.item}>
              <div className={styles.item_number}>01</div>
              <h2 className={styles.item_title}>Crafting Tailored Solutions</h2>
              <p className={styles.item_descr}>
                We specialize in creating custom solutions tailored to your businessneeds.
                Using Caspio as our base, we ensure your applications are user-friendly and feature-rich.
              </p>
            </div>
            <div className={styles.item}>
              <div className={styles.item_number}>02</div>
              <h2 className={styles.item_title}>Caspio Proficiency</h2>
              <p className={styles.item_descr}>
                We're experts in Caspio, optimizing its capabilities and seamlessly
                integrating it with other platforms like Fedex for shipments, Twilio for
                communications, and transaction rates for smoother operations.
              </p>
            </div>
            <div className={styles.item}>
              <div className={styles.item_number}>03</div>
              <h2 className={styles.item_title}>Industries We Serve</h2>
              <p className={styles.item_descr}>
                Our expertise spans healthcare, insurance, finance, investment, logistics,
                and government sectors. We understand the unique requirements of each industry.
              </p>
            </div>
            <div className={styles.item}>
              <div className={styles.item_number}>04</div>
              <h2 className={styles.item_title}>Integrating External Platforms</h2>
              <p className={styles.item_descr}>
                While Caspio is excellent, we know business growth may require integrations. We handle this by
                connecting with platforms like Fedex and Twilio, enhancing your Caspio solutions.
              </p>
            </div>
            <div className={styles.item}>
              <div className={styles.item_number}>05</div>
              <h2 className={styles.item_title}>AWS Optimization</h2>
              <p className={styles.item_descr}>
                As Caspio experts, we know when and how to enhance your applications
                with Amazon Web Services for improved scalability, security, and functionality.
              </p>
            </div>
            <div className={styles.item}>
              <div className={styles.item_number}>06</div>
              <h2 className={styles.item_title}>Digital Design Mastery</h2>
              <p className={styles.item_descr}>
                Our expertise in UI/UX from SaaS to e-commerce, with no task beyond our reach.
                We create designs that blend beauty with functionality, elevating standards with each project.
              </p>
            </div>
          </div>
          
        </div>
      </div>
      <div className={styles.polygon2}></div>
    </section>
  )
}

export default Experience
