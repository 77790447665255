import AboutUs from "../components/aboutUs/AboutUs";
import Head from "../components/aboutUsHead/Head";
import Company from "../components/company/Company";
import Map from "../components/map/Map";
import Values from "../components/ourValues/Values";
import Worx from "../components/worx/Worx";

const AboutUsPage = () => {
  return (
    <>
      <Head />
      <Worx />
      <AboutUs />
      <Map />
      <Values />
      <Company />
    </>
  )
}

export default AboutUsPage
