import styles from './style.module.css';
import services from '../../services/services';

const ServicesItems = () => {
  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.content}>
          <div className={styles.items_wrapper}>
            {services.map((item) => (
              <div className='item' key={item.id}>
                <img src={item.src} alt={item.title} className='item_img'/>
                <h2 className='item_title'>{item.title}</h2>
                <p className='item_descr'>
                  {item.descr}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServicesItems
