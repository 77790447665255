// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_content__kwDdF {
  width: 100%;
}

.style_items_wrapper__nrtDf {
  display: flex;
  gap: 60px;
  row-gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
}

/* =============================== Media Queries ====================================== */
@media screen and (max-width: 910px) and (min-width: 900px) {
  .style_items_wrapper__nrtDf {
    gap: 4px;
    row-gap: 20px;
  }
}

@media screen and (max-width: 663px) and (min-width: 633px) {
  .style_items_wrapper__nrtDf {
    gap: 30px;
    row-gap: 20px;
  }
}

@media screen and (max-width: 425px) {
  .style_items_wrapper__nrtDf {
    row-gap: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/servicesItems/style.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,SAAS;EACT,aAAa;EACb,eAAe;EACf,uBAAuB;AACzB;;AAEA,yFAAyF;AACzF;EACE;IACE,QAAQ;IACR,aAAa;EACf;AACF;;AAEA;EACE;IACE,SAAS;IACT,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".content {\n  width: 100%;\n}\n\n.items_wrapper {\n  display: flex;\n  gap: 60px;\n  row-gap: 40px;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n\n/* =============================== Media Queries ====================================== */\n@media screen and (max-width: 910px) and (min-width: 900px) {\n  .items_wrapper {\n    gap: 4px;\n    row-gap: 20px;\n  }\n}\n\n@media screen and (max-width: 663px) and (min-width: 633px) {\n  .items_wrapper {\n    gap: 30px;\n    row-gap: 20px;\n  }\n}\n\n@media screen and (max-width: 425px) {\n  .items_wrapper {\n    row-gap: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `style_content__kwDdF`,
	"items_wrapper": `style_items_wrapper__nrtDf`
};
export default ___CSS_LOADER_EXPORT___;
