import AboutUs from "../components/aboutUs/AboutUs"
import Company from "../components/company/Company"
import GetTOknow from "../components/getToknow/GetTOknow"
import Head from "../components/homeHead/Head"
import OurServices from "../components/ourServices/OurServices"

const HomePage = () => {

  

  return (
    <>
      <Head />
      <AboutUs />
      <OurServices />
      <GetTOknow />
      <Company />
    </>
  )
}

export default HomePage
