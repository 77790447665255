import styles from './style.module.css';
import React, { useState, useEffect } from 'react';

import Exp1 from '../../images/exp-img1.png';
import Exp2 from '../../images/exp-img2.png';
import Exp3 from '../../images/exp-img3.png';
import Exp4 from '../../images/exp-img4.png';
import Exp5 from '../../images/exp-img5.png';
import Exp6 from '../../images/exp-img6.png';

const OurIndustries = () => {
  const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 350px)').matches);
  const [isTablet, setIsTablet] = useState(window.matchMedia('(max-width: 1024px) and (min-width: 351px)').matches);
  const [clicked, setClicked] = useState('');
  const [id, setId] = useState('')
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia('(max-width: 350px)').matches);
      setIsTablet(window.matchMedia('(max-width: 1024px) and (min-width: 351px)').matches);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClick = (e) => {
    if (isMobile || isTablet) {
      if (id !== "" && id === e.target.id) {
        setId("");
      } else {
        setId(e.target.id);
      }
    }
  };

  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.content}>
          <div className={styles.title_block}>
            <h4 className={styles.suptitle}>Custom solutions</h4>
            <h2 className={styles.title}>Industries</h2>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.item_one} onClick={(e) => handleClick(e)}>
              <div className={styles.overlay} id='OVERLAY_ONE' style={isTablet && id === 'OVERLAY_ONE' ? { opacity: '0' } : { opacity: '1' }}></div>
              <img src={Exp1} alt="USA Government-Funded Companies" />
              <h2 className={styles.item_title}>USA Government-Funded Companies</h2>
              <div className={styles.animate_item}
                style={
                  isTablet && id === 'OVERLAY_ONE' ? { height: '65%', opacity: '1', visibility: 'visible' } :
                    isMobile && id === 'OVERLAY_ONE' ? { height: '70%', opacity: '1', visibility: 'visible' } : {}
                }>
                <h3 className={styles.animate_title}>USA Government-Funded<br /> Companies</h3>
                <span className={styles.animate_subtitle}>Funds Monitoring and Documentation:</span>
                <ul style={{ width: '93%' }} className={styles.list}>
                  <li className={styles.list_item}>Created a platform to monitor  USA government funds.</li>
                  <li className={styles.list_item}>Facilitated form filling, analysis, and document generation.</li>
                </ul>
                <div>
                  <strong>Technologies: </strong><span className={styles.animate_text}>Caspio, AWS, Stripe</span>
                </div>
              </div>
            </div>
            <div className={styles.item_two} onClick={(e) => handleClick(e)}>
              <div className={styles.overlay} id='OVERLAY_TWO' style={isTablet && id === 'OVERLAY_TWO' ? { opacity: '0' } : { opacity: '1' }}></div>
              <img src={Exp2} alt="Financial/Investment Companies" />
              <h2 className={styles.item_title}>Financial/Investment Companies</h2>
              <div className={styles.animate_item}
                style={isTablet && id === 'OVERLAY_TWO' ? { height: '73%', opacity: '1', visibility: 'visible' } :
                  isMobile && id === 'OVERLAY_TWO' ? { height: '78%', opacity: '1', visibility: 'visible' } : {}
                }>
                <h3 className={styles.animate_title}>Financial/Investment Companies</h3>
                <span className={styles.animate_subtitle}>Complex Reports and Real-Time Transactions:</span>
                <ul style={{ width: '93%' }} className={styles.list}>
                  <li className={styles.list_item}>Created complex reports for various transaction types.</li>
                  <li className={styles.list_item}>Integrated bank accounts through Plaid for real-time balance updates.</li>
                  <li className={styles.list_item}>Matched and added transactions seamlessly within the system.</li>
                </ul>
                <div>
                  <strong>Technologies: </strong><span className={styles.animate_text}>Caspio, AWS, Plaid</span>
                </div>
              </div>
            </div>
            <div className={styles.item_three} onClick={(e) => handleClick(e)}>
              <div className={styles.overlay} id='OVERLAY_THREE' style={isTablet && id === 'OVERLAY_THREE' ? { opacity: '0' } : { opacity: '1' }}></div>
              <img src={Exp3} alt="Healthcare" />
              <h2 className={styles.item_title}>Healthcare</h2>
              <div className={styles.animate_item}
                style={isTablet && id === 'OVERLAY_THREE' ? { height: '67%', opacity: '1', visibility: 'visible' } :
                  isMobile && id === 'OVERLAY_THREE' ? { height: '72%', opacity: '1', visibility: 'visible' } : {}
                }>
                <h3 className={styles.animate_title}>Healthcare</h3>
                <span className={styles.animate_subtitle}>Appointment Booking and Result Tracking</span>
                <ul style={{ width: '93%' }} className={styles.list}>
                  <li className={styles.list_item}>Integrated calendar for appointment booking</li>
                  <li className={styles.list_item}>Utilized Twilio for calls through the platform</li>
                  <li className={styles.list_item}>Created a database for analysis results and progress tracking</li>
                </ul>
                <div>
                  <strong>Technologies: </strong><span className={styles.animate_text}>Caspio, AWS, Calendly, Twilio</span>
                </div>
              </div>
            </div>
            <div className={styles.item_four} onClick={(e) => handleClick(e)}>
              <div className={styles.overlay} id='OVERLAY_FOUR' style={isTablet && id === 'OVERLAY_FOUR' ? { opacity: '0' } : { opacity: '1' }}></div>
              <img src={Exp4} alt="Logistics" />
              <h2 className={styles.item_title}>Logistics</h2>
              <div className={styles.animate_item}
                style={isTablet && id === 'OVERLAY_FOUR' ? { height: '65%', opacity: '1', visibility: 'visible' } :
                  isMobile && id === 'OVERLAY_FOUR' ? { height: '70%', opacity: '1', visibility: 'visible' } : {}
                }>
                <h3 className={styles.animate_title}>Logistics</h3>
                <span className={styles.animate_subtitle}>Efficient Load Tracking</span>
                <ul style={{ width: '93%' }} className={styles.list}>
                  <li className={styles.list_item}>Integrated Shipengine for load tracking.</li>
                  <li className={styles.list_item}>Utilized Google Maps for real-time position tracking.</li>
                  <li className={styles.list_item}>Implemented SMS/Email notifications for load updates.</li>
                </ul>
                <div>
                  <strong>Technologies: </strong><span className={styles.animate_text}>Caspio, AWS, Shipengine, Google Maps</span>
                </div>
              </div>
            </div>
            <div className={styles.item_five} onClick={(e) => handleClick(e)}>
              <div className={styles.overlay} id='OVERLAY_FIVE' style={isTablet && id === 'OVERLAY_FIVE' ? { opacity: '0' } : { opacity: '1' }}></div>
              <img src={Exp5} alt="Insurance" />
              <h2 className={styles.item_title}>Insurance</h2>
              <div className={styles.animate_item}
                style={isTablet && id === 'OVERLAY_FIVE' ? { height: '60%', opacity: '1', visibility: 'visible' } :
                  isMobile && id === 'OVERLAY_FIVE' ? { height: '70%', opacity: '1', visibility: 'visible' } : {}
                }>
                <h3 className={styles.animate_title}>Insurance</h3>
                <span className={styles.animate_subtitle}>Automated Insurance Agreements</span>
                <ul style={{ width: '93%' }} className={styles.list}>
                  <li className={styles.list_item}>Developed a flow for creating insurance agreements.</li>
                  <li className={styles.list_item}>Calculated rates based on form questions.</li>
                  <li className={styles.list_item}>Integrated with Stripe for subscription management</li>
                </ul>
                <div>
                  <strong>Technologies: </strong><span className={styles.animate_text}>Caspio, AWS, Stripe</span>
                </div>
              </div>
            </div>
            <div className={styles.item_six} onClick={(e) => handleClick(e)}>
              <div className={styles.overlay} id='OVERLAY_SIX' style={isTablet && id === 'OVERLAY_SIX' ? { opacity: '0' } : { opacity: '1' }}></div>
              <img src={Exp6} alt="HoReCa" />
              <h2 className={styles.item_title}>HoReCa</h2>
              <div className={styles.animate_item}
                style={isTablet && id === 'OVERLAY_SIX' ? { height: '70%', opacity: '1', visibility: 'visible' } :
                  isMobile && id === 'OVERLAY_SIX' ? { height: '75%', opacity: '1', visibility: 'visible' } : {}
                }>
                <h3 className={styles.animate_title}>HoReCa</h3>
                <span className={styles.animate_subtitle}>Developed restaurant management system</span>
                <ul style={{ width: '94%' }} className={styles.list}>
                  <li className={styles.list_item}>Developed a System for Restaurant Operations Management</li>
                  <li className={styles.list_item}>Implemented Customizable Checklist Creation</li>
                  <li className={styles.list_item}>Integrated a Reporting and Notification System</li>
                </ul>
                <div>
                  <strong>Technologies: </strong><span className={styles.animate_text}>Caspio, API</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurIndustries
