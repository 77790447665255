import styles from './styles.module.css';
import Process from '../../images/process.png';

const WorkingProcess = () => {
  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.content}>
          <h2 className={styles.title}>Working Process</h2>
          <div className={styles.wrapper} style={{ position: 'relative' }}>
            <img className={styles.img} src={Process} alt="Working Process" />
            <div className={styles.one}>1</div>
            <span className={`${styles.process_name} ${styles.process_name_one}`}>Analysis</span>
            <div className={`${styles.descr_block} ${styles.descr_block_one}`}>
              <p className={`${styles.descr} ${styles.descr_one}`}>Resource Allocation,<br /> time and cost<br /> esstimation</p>
              <div className={`${styles.line} ${styles.line_one}`}></div>
            </div>
            <div className={styles.two}>2</div>
            <span className={`${styles.process_name} ${styles.process_name_two}`}>Planing</span>
            <div className={`${styles.descr_block} ${styles.descr_block_two}`}>
              <p className={`${styles.descr} ${styles.descr_two}`}>Coding,<br /> integration,<br /> unit testing</p>
              <div className={`${styles.line} ${styles.line_two}`}></div>
            </div>
            <div className={styles.three}>3</div>
            <span className={`${styles.process_name} ${styles.process_name_three}`}>Design</span>
            <div className={`${styles.descr_block} ${styles.descr_block_three}`}>
              <p className={`${styles.descr} ${styles.descr_three}`}>Launch,<br /> Rollout,<br /> Backup</p>
              <div className={`${styles.line} ${styles.line_three}`}></div>
            </div>
            <div className={styles.four}>4</div>
            <span className={`${styles.process_name} ${styles.process_name_four}`}>Development</span>
            <div className={`${styles.descr_block} ${styles.descr_block_four}`}>
              <p className={`${styles.descr} ${styles.descr_four}`}>Gathering<br /> requirements,<br /> analysis</p>
              <div className={`${styles.line} ${styles.line_four}`}></div>
            </div>
            <div className={styles.five}>5</div>
            <span className={`${styles.process_name} ${styles.process_name_five}`}>Testing</span>
            <div className={`${styles.descr_block} ${styles.descr_block_five}`}>
              <p className={`${styles.descr} ${styles.descr_five}`}>Wireframing,<br /> UX/UI design,<br /> Prototyping</p>
              <div className={`${styles.line} ${styles.line_five}`}></div>
            </div>
            <div className={styles.six}>6</div>
            <span className={`${styles.process_name} ${styles.process_name_six}`}>Deployment</span>
            <div className={`${styles.descr_block} ${styles.descr_block_six}`}>
              <p className={`${styles.descr} ${styles.descr_six}`}>Integration, system,<br /> performance,<br /> security, UAT testing</p>
              <div className={`${styles.line} ${styles.line_six}`}></div>
            </div>
            <div className={styles.seven}>7</div>
            <span className={`${styles.process_name} ${styles.process_name_seven}`}>Maintance</span>
            <div className={`${styles.descr_block} ${styles.descr_block_seven}`}>
              <p className={`${styles.descr} ${styles.descr_seven}`}>Monitoring,<br /> Updating,<br /> Support</p>
              <div className={`${styles.line} ${styles.line_seven}`}></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WorkingProcess
