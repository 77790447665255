import styles from './style.module.css';
import Form from '../form/Form';


const Company = () => {
  return (
    <section id='scroll' className={styles.section}>
      <div style={{
        position: 'absolute',
        width: 'inherit',
        height: '388px',
        background: 'rgba(0, 0, 0, 0.6)',
        top: '-2px'

      }}>
        <div className={styles.content}>
          <h2 className={styles.title}>
            Don't Hesitate To Contact Us For Better Information and Services
          </h2>
          <p className={styles.descr}>
            Join our growing circle of satisfied clients.
            Share your contact information below and we'll reach out with
            details about our exceptional services.
          </p>
          <Form />
        </div>
      </div>
    </section>
  )
}

export default Company
