import styles from './style.module.css';
import tools from './ourToolkit';

const Toolkit = () => {
  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.content}>
          <h2 className={styles.title}>Our Technological Toolkit</h2>
          <div className={styles.wrapper}>
            <div className={styles.inner}>
              <h3 className={styles.inner_title}>Software Development</h3>
              <div className={styles.tools_block}>
                {tools.slice(0, 9).map((tool) => {
                  return (
                    <div className={styles.tools} id={tool.id}>
                      <img src={tool.src} alt={tool.alt} />
                      <p className={styles.tools_title}>{tool.title}</p>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className={styles.line}></div>
            <div className={styles.inner}>
              <h3 className={styles.inner_title}>Frameworks</h3>
              <div className={styles.tools_block}>
                {tools.slice(9, 15).map((tool) => {
                  return (
                    <div className={styles.tools} id={tool.id}>
                      <img src={tool.src} alt={tool.alt} />
                      <p className={styles.tools_title}>{tool.title}</p>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className={styles.line}></div>
            <div className={styles.inner}>
              <h3 className={styles.inner_title}>Design</h3>
              <div className={styles.tools_block}>
                {tools.slice(15, 21).map((tool) => {
                  return (
                    <div className={styles.tools} id={tool.id}>
                      <img src={tool.src} alt={tool.alt} />
                      <p className={styles.tools_title}>{tool.title}</p>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className={styles.line}></div>
            <div className={styles.inner}>
              <h3 className={styles.inner_title}>CMS</h3>
              <div className={styles.tools_block}>
                {tools.slice(21, 24).map((tool) => {
                  return (
                    <div className={styles.tools} id={tool.id}>
                      <img src={tool.src} alt={tool.alt} />
                      <p className={styles.tools_title}>{tool.title}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Toolkit
