import styles from './style.module.css';

import MapImg from '../../images/map.png';

const Map = () => {

  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.content}>
          <div className={styles.title_block}>
            <h4 className={styles.suptitle}>Countries of our clients</h4>
            <h2 className={styles.title}>Let the numbers speak</h2>
          </div>
          <div className={styles.bottom_block}>
            <div className={styles.left_side}>
              <div className={styles.inner}>
                <span className={styles.inner_number}>7+</span>
                <span className={styles.inner_title}>Years of Experience</span>
              </div>
              <div className={styles.inner}>
                <span className={styles.inner_number}>13+</span>
                <span className={styles.inner_title}>Countries Covered</span>
              </div>
              <div className={styles.inner}>
                <span className={styles.inner_number}>60+</span>
                <span className={styles.inner_title}>Projects Delivered</span>
              </div>
            </div>
            <div className={styles.right_side}>
              <img src={MapImg} alt="Map" />
              <div className={`${styles.country} ${styles.canada}`}>
                <div className={styles.circle}></div>
                <span className={styles.country_name}>Canada</span>
              </div>
              <div className={`${styles.country} ${styles.usa}`}>
                <div className={styles.circle}></div>
                <span className={styles.country_name}>USA</span>
              </div>
              <div className={`${styles.country} ${styles.england}`}>
                <div className={styles.circle}></div>
                <span className={styles.country_name}>England</span>
              </div>
              <div className={`${styles.country} ${styles.nederland}`}>
                <div className={styles.circle}></div>
                <span className={styles.country_name}>Nederland</span>
              </div>
              <div className={`${styles.country} ${styles.france}`}>
                <div className={styles.circle}></div>
                <span className={styles.country_name}>France</span>
              </div>
              <div className={`${styles.country} ${styles.greece}`}>
                <div className={styles.circle}></div>
                <span className={styles.country_name}>Greece</span>
              </div>
              <div className={`${styles.country} ${styles.ukraine}`}>
                <div className={styles.circle}></div>
                <span className={styles.country_name}>Ukraine</span>
              </div>
              <div className={`${styles.country} ${styles.armenia}`}>
                <div className={styles.circle}></div>
                <span className={styles.country_name}>Armenia</span>
              </div>
              <div className={`${styles.country} ${styles.lebanon}`}>
                <div className={styles.circle}></div>
                <span className={styles.country_name}>Lebanon</span>
              </div>
              <div className={`${styles.country} ${styles.uae}`}>
                <div className={styles.circle}></div>
                <span className={styles.country_name}>U.A.E</span>
              </div>
              <div className={`${styles.country} ${styles.india}`}>
                <div className={styles.circle}></div>
                <span className={styles.country_name}>India</span>
              </div>
              <div className={`${styles.country} ${styles.australia}`}>
                <div className={styles.circle}></div>
                <span className={styles.country_name}>Australia</span>
              </div>
              <div className={`${styles.country} ${styles.russia}`}>
                <div className={styles.circle}></div>
                <span className={styles.country_name}>Russia</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Map
