import styles from './style.module.css';
import Nav from '../navigation/Nav';
import SkewButton from '../skewButton/SkewButton';


import Programmer from '../../icons/programmer.svg';
import CustomerSupport from '../../icons/customer-support.svg';

const Head = () => {
  return (
    <section className={styles.section}>
      <Nav />
      <div className='container'>
        <div className={styles.content}>
          <div className={styles.right__side}>
            <h1 className={styles.title}>Development company</h1>
            <p className={styles.subtitle}>with high responsibility</p>
            <SkewButton forRightWhite={true} ButtonTitle='Contact us' />
          </div>
          <div className={styles.bottom}>
            <div className={styles.item}>
              <img src={CustomerSupport} alt="programmer" />
              <h3 className={styles.item_title}>Extensive Development & Support</h3>
              <p className={styles.item_descr}>
                We offer advanced mobile and enterprise solutions with rigorous testing
                and reliable IT support, ensuring technology excellence and upgrades.
              </p>
            </div>
            <div className={styles.item}>
              <img src={Programmer} alt="customer-support" />
              <h3 className={styles.item_title}>Strategic IT Partnerships</h3>
              <p className={styles.item_descr}>
                Work with Worx for growth-driven IT partnerships. More than just coding,
                we offer dynamic collaborations tailored to your business.
              </p>
            </div>
            <div className={styles.item}>
              <h3 className={styles.item_title}>Custom Request</h3>
              <p className={styles.item_descr}>
                Tell us what you need, and we'll tailor our technology to fit your goals.,
              </p>
              <SkewButton forLeftWhite={true} ButtonTitle='Find Solution' />
            </div>
          </div>
        </div>
        
      </div>
      
    </section>
  )
}

export default Head
