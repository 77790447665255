import styles from './style.module.css';
import SkewButton from '../skewButton/SkewButton';
import { useLocation } from 'react-router-dom';

import AboutUsImg from '../../images/about-us.png';
import Done from '../../icons/done.svg';


const AboutUs = () => {
  const location = useLocation();

  return (
    <section className={styles.section}>
      <div className='container' style={{ padding: '0px 0px' }}>
        <div className={styles.content}>
          <div className={styles.left_side}>
            <img src={AboutUsImg} alt="our team" />
            <div className={styles.left_side__miniBlock}>
              <p className={styles.left_side__miniBlock_descr}>
                We believe that we're on the face of the Earth to make great products.
              </p>
              <h4 className={styles.left_side__miniBlock_title}>Tim Cook-CEO of Apple Inc.</h4>
              <div className={styles.polygon}></div>
            </div>
          </div>
          <div className={styles.right_side}>
            <div className={styles.right_side__top}>
              <h3 className={styles.right_side__title}>About us</h3>
              <h2 className={styles.right_side__subtitle}>Connecting You with Our Story</h2>
              <p className={styles.right_side__descr}>
                At Worx, we combine tech expertise with strategic insight to develop IT solutions that drive business success.
                Celebrating over five years of service, we’re your partners in digital transformation.
              </p>
            </div>
            <div className={styles.right_side__mid}>
              <p className={styles.right_side_mid__title} style={{ marginLeft: '75px' }}>
                In a world filled with complex tech, we deliver simplicity - clear, functional, and efficient IT solutions.
              </p>
              <p className={styles.right_side_mid__descr} style={{ marginLeft: '75px' }}>
                Our expertise is your advantage. At Worx, we merge analytical prowess with creative thinking to
                deliver tailored IT solutions. From streamlining operations with process automation to
                safeguarding your data with robust IT support, we empower your business at every digital touchpoint.
              </p>
            </div>
            {location.pathname !== '/about-us' && <SkewButton forRightBG={true} ButtonTitle="Know more" />}
            
            <ul className={styles.right_side__list}>
              <div className={styles.item_inner}>
                <img style={{width: '14px'}} src={Done} alt="" />
                <li className={styles.list_item}>We are committed to providing quality IT services.</li>
              </div>
              <div className={styles.item_inner}>
                <img style={{width: '14px'}} src={Done} alt="" />
                <li className={styles.list_item}>Provided by experts to help challenge critical activities.</li>
              </div>
              <div className={styles.item_inner}>
                <img style={{width: '14px'}} src={Done} alt="" />
                <li className={styles.list_item}>Really know the true needs and expectations of customers.</li>
              </div>
            </ul>
          </div>
        </div>
      </div>

      <div className={styles.line} >
      </div>
    </section>
  )
}

export default AboutUs
