// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_content__L\\+zqv {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 0 20px;
  row-gap: 18px;
  margin-top: 215px;
  margin-bottom: 104px;
  text-align: center;
}

.style_title__hnh7S {
  color: #318686;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.style_descr__bdB-s {
  color: #787878;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  width: 100%;
  max-width: 863px;
}

/* ================================= Media Queries ================================ */
@media screen and (max-width: 900px) {
  .style_content__L\\+zqv {
    margin-top: 168px;
    margin-bottom: 48px;
  }
  .style_descr__bdB-s {
    font-size: 14px;
    max-width: 777px;
  }
}
@media screen and (max-width: 704px) and (min-width: 425px) {
  .style_title__hnh7S {
    font-size: 28px;
  }
}
@media screen and (max-width: 424px) {
  .style_content__L\\+zqv {
    padding: unset;
  }
  .style_title__hnh7S {
    font-size: 24px;
  }

  .style_descr__bdB-s {
    font-size: 10px;
    line-height: 14px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/servicesHead/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,mBAAmB;EACnB,eAAe;EACf,aAAa;EACb,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;EACX,gBAAgB;AAClB;;AAEA,qFAAqF;AACrF;EACE;IACE,iBAAiB;IACjB,mBAAmB;EACrB;EACA;IACE,eAAe;IACf,gBAAgB;EAClB;AACF;AACA;EACE;IACE,eAAe;EACjB;AACF;AACA;EACE;IACE,cAAc;EAChB;EACA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;AACF","sourcesContent":[".content {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: center;\n  padding: 0 20px;\n  row-gap: 18px;\n  margin-top: 215px;\n  margin-bottom: 104px;\n  text-align: center;\n}\n\n.title {\n  color: #318686;\n  font-size: 50px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n}\n\n.descr {\n  color: #787878;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 25px;\n  width: 100%;\n  max-width: 863px;\n}\n\n/* ================================= Media Queries ================================ */\n@media screen and (max-width: 900px) {\n  .content {\n    margin-top: 168px;\n    margin-bottom: 48px;\n  }\n  .descr {\n    font-size: 14px;\n    max-width: 777px;\n  }\n}\n@media screen and (max-width: 704px) and (min-width: 425px) {\n  .title {\n    font-size: 28px;\n  }\n}\n@media screen and (max-width: 424px) {\n  .content {\n    padding: unset;\n  }\n  .title {\n    font-size: 24px;\n  }\n\n  .descr {\n    font-size: 10px;\n    line-height: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `style_content__L+zqv`,
	"title": `style_title__hnh7S`,
	"descr": `style_descr__bdB-s`
};
export default ___CSS_LOADER_EXPORT___;
