import MonitorIcon from './servicesIcons/item-monitor.svg';
import DesignIcon from './servicesIcons/item-design.svg';
import CloudIcon from './servicesIcons/item-cloud.svg';
import CloudIntegrationIcon from './servicesIcons/item-cloud-integration.svg';
import CaspioIcon from './servicesIcons/item-caspio-new.svg';
import BubbleIcon from './servicesIcons/item-bubble.svg'
import MobileUpIcon from './servicesIcons/item-mobileup.svg';
import CubeIcon from './servicesIcons/item-cube.svg';
import QATestingIcon from './servicesIcons/item-qa-testing.svg';
import DataBusinessIcon from './servicesIcons/item-data-business.svg';
import SupportMaintenanceIcon from './servicesIcons/item-support-maintenance.svg';
import DatabaseIcon from './servicesIcons/item-database-crm.svg';
import ErpIcon from './servicesIcons/item-erp.svg';
import TechnologyIcon from './servicesIcons/item-technology.svg';
import CrmSolutionIcon from './servicesIcons/item-crm-solution.svg';

const Services = [
  {
    id: 1,
    class: 'monitor-icon',
    src: MonitorIcon,
    title: 'Software Development',
    descr:
      'We develop custom software solutions that are as unique as your business. From automation to full-scale system architecture, we deliver reliable and effective software tailored to your specific needs.',
  },
  {
    id: 2,
    class: 'design-icon',
    src: DesignIcon,
    title: 'UX/UI Design',
    descr:
      'Design interfaces that marry functionality with aesthetics. Our designs aim to create intuitive user experiences that drive engagement and satisfaction.',
  },
  {
    id: 4,
    class: 'caspio-icon',
    src: CaspioIcon,
    title: 'Caspio Development',
    descr:
      'With specialized expertise in Caspio, we deliver custom applications that enhance your data management and streamline business operations.',
  },
  {
    id: 5,
    class: 'bubble-icon',
    src: BubbleIcon,
    title: 'Bubble Development',
    descr:
      'We develop tailored no-code applications using Bubble.io, enabling clients to streamline workflows, integrate advanced features, and launch scalable digital solutions quickly and efficiently.',
  },
  {
    id: 3,
    class: 'cloud-icon',
    src: CloudIcon,
    title: 'Cloud Services',
    descr:
      'Transition to the cloud seamlessly with our expert guidance. Our cloud services ensure your data is accessible, secure, and optimized for performance, supporting your growth every step of the way.',
  },
  {
    id: 7,
    class: 'cloud-integr-icon',
    src: CloudIntegrationIcon,
    title: 'Cloud Integration',
    descr:
      'Worx Cloud Integration optimizes your operations. Connect cloud services for improved efficiency and productivity, unleashing the full potential of your digital environment.',
  },
  {
    id: 6,
    class: 'mobile-icon',
    src: MobileUpIcon,
    title: 'Mobile App Development',
    descr:
      'We build mobile applications that are not only visually striking but also intuitively navigable, ensuring a superior user experience across all devices.',
  },
  {
    id: 8,
    class: 'cube-icon',
    src: CubeIcon,
    title: 'Management & Process Automation',
    descr:
      'We develop custom software solutions that are as unique as your business. From automation to full-scale system architecture, we deliver reliable and effective software tailored to your specific needs.',
  },
  {
    id: 9,
    class: 'qa-testing-icon',
    src: QATestingIcon,
    title: 'QA & Testing',
    descr:
      'Ensure top quality in your projects with our comprehensive QA and testing services. We work to make your products solid, trustworthy, and ready to go live.',
  },
  {
    id: 10,
    class: 'databusiness-icon',
    src: DataBusinessIcon,
    title: 'Data Analytics and Business Intelligence',
    descr:
      'Inform decision-making with our data analytics and business intelligence. We transform data into growth-driving insights.',
  },
  {
    id: 11,
    class: 'support-icon',
    src: SupportMaintenanceIcon,
    title: 'IT Support and Maintenance',
    descr:
      'Keep your IT infrastructure at its best with our support and maintenance services. We provide round-the-clock support to ensure seamless business operations.',
  },
  {
    id: 12,
    class: 'database-icon',
    src: DatabaseIcon,
    title: 'Database Management',
    descr:
      'Our database management services ensure your data is not just stored but optimized for security, performance, and accessibility.',
  },
  {
    id: 13,
    class: 'erp-icon',
    src: ErpIcon,
    title: 'ERP Solutions',
    descr:
      ' Integrate and manage all aspects of your business with our custom ERP solutions. We deliver systems that streamline processes, improve data visibility, and support growth.',
  },
  {
    id: 14,
    class: 'crm-solution-icon',
    src: CrmSolutionIcon,
    title: 'CRM Solutions',
    descr:
      'Build stronger customer relationships with our tailored CRM solutions. Gain valuable insights, enhance engagement, and drive sales with strategic CRM implementation.',
  },
  {
    id: 15,
    class: 'technology-icon',
    src: TechnologyIcon,
    title: 'Technology Migration and Upgrade Services',
    descr:
      'Stay current with our tech migration and upgrades. We keep your business competitive in the digital landscape.',
  },
];
export default Services