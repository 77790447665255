import styles from './styles.module.css';
import { useState, useEffect } from 'react';

import Clock from '../clock/Clock';
import Nav from '../navigation/Nav';


const Head = () => {

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 540px)').matches);
  // DOM for mobile device ======================================================================
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia('(max-width: 540px)').matches);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Functionality for sending mail ========================================================================

  const handleEmailChange = (event) => {
    // Validation for error message email =================================================
    setEmail(event.target.value);
    let errorMsgEmail = '';
    if (!/\S+@\S+\.\S+/.test(event.target.value)) {
      errorMsgEmail = 'Please enter a valid email address'
      if (!event.target.value.trim()) {
        errorMsgEmail = "Required field.";
      }
    }
    setEmailError(
      errorMsgEmail
    );
  };
  const handleSubmit = async () => {
    let valid = true;
    // Validation for error message email ===================================================
    if (!email.trim() || !/\S+@\S+\.\S+/.test(email)) {
      let errorMsgEmail = '';

      if (!/\S+@\S+\.\S+/.test(email)) {
        errorMsgEmail = 'Please enter a valid email address'
        if (!email.trim()) {
          errorMsgEmail = 'Required field.'
        }
      }
      setEmailError(errorMsgEmail);
      valid = false;
    }
    if (valid) {
      console.log("test");
      const url = 'https://worxback.worx.am/sendMail';
      const data = {
        email: email
      };
      const params = new URLSearchParams();
      for (const [key, value] of Object.entries(data)) {
        params.append(key, value);
      }
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: params.toString(),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const responseData = await response.json();
        console.log(responseData);
        alert("Your message was sent!");
        setEmail('');
      } catch (error) {
        console.error('Error:', error.message);
      }
    }
  }

  return (
    <section className={styles.section}>
      <Nav />
      <div className="container">
        <div className={styles.content}>
          <Clock />
          {!isMobile ? (
            <div className={styles.right_side}>
              <div className={styles.inner}>
                <h1 className={styles.title}>Get Ready:<br /> Something New Is Coming!</h1>
                <p className={styles.subtitle}>Enter your email to be notified about our new product launch.</p>
              </div>
              <form className={styles.form} onClick={(e) => e.preventDefault}>
                <div className={styles.form_block}>
                  <input type="email" placeholder='E-mail' name='email' value={email} required onChange={handleEmailChange} />
                  <div style={{ position: 'relative', height: '50px' }} >
                    <button type='button' className={styles.btn} onClick={handleSubmit}>
                      <span>Notify me</span>
                    </button>
                    <div className={styles.shadow}></div>
                  </div>
                </div>
                {emailError && <span className="error_desktop">{emailError}</span>}
              </form>
            </div>
          ) : (
            <div className={styles.right_side}>
              <div className={styles.inner}>
                <h1 className={styles.title}>Get Ready:<br /> Something New Is Coming!</h1>
                <p className={styles.subtitle}>Enter your email to be notified about our new product launch.</p>
              </div>
              <form className={styles.form} onClick={(e) => e.preventDefault}>
                <div className={styles.form_block}>
                  <input type="email" placeholder='E-mail' name='email' value={email} required onChange={handleEmailChange} />
                </div>
                {emailError && <span className='error_mobile'>{emailError}</span>}
                <div style={{ position: 'relative', height: '50px' }} >
                  <button type='button' className={styles.btn} onClick={handleSubmit}>
                    <span>Notify me</span>
                  </button>
                  <div className={styles.shadow}></div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Head
