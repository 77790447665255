import styles from './style.module.css';
import Nav from '../navigation/Nav';


const Head = () => {
  return (
    <section className={styles.section}>
      <Nav />
      <div className="container">
        <div className={styles.content}>
          <div className={styles.right_side}>
            <h2 className={styles.head_suptitle}>Vision, Excellence, and Personalized Service.</h2>
            <h1 className={styles.head_title}>Our Team = Your Team</h1>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Head
